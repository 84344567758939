<template>
  <v-row
    v-if="checkboxContent"
  >
    <v-col
      cols="12"
      class="py-0 d-flex align-center justify-space-between"
    >
      <label class="font-weight-medium text-body-1">{{ title }}</label>
      <v-btn
        x-small
        color="secondary"
        @click.prevent="onSelectAll"
      >
        {{
          checkboxContent.length !== selectedOption.length ? $t('tooltip.mark_all') : $t('tooltip.unmark_all')
        }}
      </v-btn>
    </v-col>
    <v-col
      v-for="(item) in checkboxContent"
      :key="item.value"
      v-bind="gridColumn"
      @click.prevent="!readonly ? handlerClick(item.value) : null"
    >
      <label
        class="label-custom-input custom-checkbox rounded cursor-pointer"
        :class="selectedOption.includes(item.value) ? 'active' : ''"
      >
        <div>
          <v-checkbox
            v-model="selectedOption"
            :value="item.value"
            class="ma-0 pa-0"
            color="secondary"
            hide-details
            :readonly="readonly"
            @click.prevent="!readonly ? handlerClick(item.value) : null"
          />
        </div>
        <slot :item="item">
          <div class="flex-grow-1">
            <div class=" align-center mb-1">
              <h6 class="cr-title text-base">
                {{ item.title }}
              </h6>
              <v-spacer />
              <span
                v-if="item.desc"
                class="text-sm text-disabled"
              >{{ item.desc }}</span>
            </div>
            <p class="text-sm mb-0">
              {{ item.subtitle }}
            </p>
          </div>
        </slot>
      </label>
    </v-col>
  </v-row>
</template>

<script>
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'

export default {
  model: {
    prop: 'selectedCheckbox',
    event: 'update:selectedCheckbox',
  },
  props: {
    selectedCheckbox: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    checkboxContent: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    gridColumn: {
      type: null,
      required: false,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const timer = ref(null)
    const selectedsModel = ref([])
    const selectedOption = computed({
      get: () => props.selectedCheckbox,
      set: val => {
        emit('update:selectedCheckbox', val)
      },
    })

    const handlerClick = e => {
      if (timer.value) clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        let selecteds = selectedOption.value
        if (selecteds.includes(e)) {
          selecteds = selecteds.filter(opt => opt !== e)
        } else {
          selecteds.push(e)
        }
        selectedOption.value = selecteds
        selectedsModel.value = selecteds
      }, 100)
    }

    const onSelectAll = () => {
      if (props.checkboxContent.length !== selectedOption.value.length) selectedOption.value = props.checkboxContent.map(e => e.value)
      else selectedOption.value = []
    }

    watch(selectedsModel, () => {
      if (selectedsModel.value.length === 0) selectedOption.value = []
    })

    onMounted(() => {
      setTimeout(() => {
        selectedsModel.value = props.selectedCheckbox
      }, 200)
    })

    return {
      selectedsModel,
      selectedOption,
      handlerClick,
      onSelectAll,
    }
  },
}

</script>

<style lang="scss">
.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.14);

  .v-checkbox {
    margin-block-start: -0.375rem;
  }

  .cr-title {
    font-weight: 500;
  }
}
</style>
