<template>
  <v-row>
    <v-col
      cols="12"
      md="8"
    >
      <!-- color="#37a764" -->
      <v-btn
        v-if="isModeSaveEdit && !noSave"
        class="mr-2"
        color="secondary"
        dark
        @click="onSave"
      >
        <!-- <v-icon>
          {{ icons.mdiContentSaveOutline }}
        </v-icon> -->
        {{ saveLabel || t("tooltip.save") }}
      </v-btn>

      <!-- color="#e15b5b" -->
      <v-btn
        v-if="isModeSaveEdit"
        class="mr-2"
        color="secondary"
        dark
        outlined
        @click="onCancel"
      >
        <!-- <v-icon>
          {{ icons.mdiClose }}
        </v-icon> -->
        {{ t("tooltip.cancel") }}
      </v-btn>

      <!-- color="#5b66e1" -->
      <v-btn
        v-if="!noArrow"
        class="mr-2"
        color="secondary"
        dark
        outlined
        @click="onNextTab"
      >
        <!-- <v-icon>
          {{ icons.mdiArrowRight }}
        </v-icon> -->
        {{ t("tooltip.next_tab") }}
      </v-btn>
    </v-col>

    <v-col
      cols="12"
      md="4"
      :class="{ 'text-end': $vuetify.breakpoint.mdAndUp }"
    >
      <span
        v-if="infoRequired"
        class="red--text mb-3"
      >{{ t('required.label') }}</span>
    </v-col>
  </v-row>
</template>

<script>
import { mdiContentSaveOutline, mdiClose, mdiArrowRight } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'

export default {
  props: {
    tabNumber: {
      // next tab to go
      type: Number,
      default: 0,
    },

    // tells the component if user is in save and edit mode
    isModeSaveEdit: Boolean,

    // tells the component if user is going to save
    isModeSave: Boolean,

    // tells the component if user is onling watching an element
    isViewMode: Boolean,

    // hide arrow buttom
    noArrow: {
      type: Boolean,
      default: false,
    },

    // shows users there's required data to save
    infoRequired: {
      type: Boolean,
      default: false,
    },

    // hide cancel buttom
    noCancel: {
      type: Boolean,
      default: false,
    },

    // hide save buttom
    noSave: {
      type: Boolean,
      default: false,
    },

    saveLabel: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    // i18n
    const { t } = useUtils()

    // these methods tell to parent component an action
    const onNextTab = () => {
      // go to next tab emit method
      emit('onNextTab', props.tabNumber + 1)
    }

    const onPreTab = () => {
      // go to previous tab emit method
      if (props.tabNumber !== 0) {
        emit('onPreTab', props.tabNumber - 1)
      }
    }

    const onSave = () => {
      // save emit method
      emit('onSave')
    }

    const onCancel = () => {
      // cancel emit method
      emit('onCancel')
    }

    return {
      // Methods
      onNextTab,
      onPreTab,
      onSave,
      onCancel,

      // i18n
      t,

      // Icons
      icons: {
        mdiContentSaveOutline,
        mdiClose,
        mdiArrowRight,
      },
    }
  },
}
</script>

<style>
.btn-cancel-flat {
  right: 114px !important;
  bottom: 10px !important;
}
.btn-save-flat {
  right: 65px !important;
  bottom: 10px !important;
}

.btn-save-no-arrow {
  right: 20px !important;
  bottom: 10px !important;
}

.btn-cancel-no-arrow {
  right: 70px !important;
  bottom: 10px !important;
}

.btn-cancel-no-save {
  right: 65px !important;
  bottom: 10px !important;
}

.btn-arrow {
  bottom: 10px !important;
}

.params {
  color: darkgrey;
  position: absolute;
  bottom: 0;
  left: 20px;
}

.cancel-button-delete {
  display: none !important;
}
</style>
